import { Expose, Type } from "class-transformer";
import { ImageFormatEnum } from "./enumerations";
import { GenerateImageResult, GenerateMidjourneyImageResult } from "./generate-image-result.models";
import { ImagingProviderEnum } from "./discriminators";

export class GeneratedImage {
	@Expose() id!: number;
	@Expose() index!: number;
	@Expose() name!: string;
	@Expose() mediaLink!: string;
	@Expose() imageFormatId!: ImageFormatEnum;
	@Expose() width!: number;
	@Expose() height!: number;
	@Expose() @Type(() => GenerateImageResult, {
		discriminator: {
			property: '__type',
			subTypes: [
				{ value: GenerateMidjourneyImageResult, name: ImagingProviderEnum.Midjourney.toString() }
			],
		},
	})
	generateImageResult!: GenerateImageResult;
}
