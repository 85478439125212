import { Routes } from '@angular/router';
import { isAuthenticatedGuard } from './shared/guards/is-authenticated.guard';
import { MainPageComponent } from './features/common/pages/main/main.component';
import { ProfilePageComponent } from './features/users/pages/profile/profile.component';
import { GenerationsPageComponent } from './features/imaging/pages/generations/generations.component';

export const routes: Routes = [
	{ path: '', component: MainPageComponent, pathMatch: 'full' },
	{ path: 'profile', component: ProfilePageComponent, canActivate: [isAuthenticatedGuard] },
	{ path: 'generations', component: GenerationsPageComponent, canActivate: [isAuthenticatedGuard] },
];
