import { Expose, Type } from "class-transformer";
import { ImagingProviderEnum } from "./discriminators";
import { PostprocessGenerateImageResultStatusEnum } from "./enumerations";
import { GenerateImageTask, GenerateMidjourneyImageTask } from "./generate-image-task.models";
import { GeneratedImage } from "./generated-image.models";

export abstract class GenerateImageResult {
	@Expose() __type!: ImagingProviderEnum;
	@Expose() id!: number;
	@Expose() externalId!: string;
	@Expose() rawJsonResult!: string;
	@Expose() postprocessStatusId!: PostprocessGenerateImageResultStatusEnum;
	@Expose() @Type(() => GenerateImageTask, {
		discriminator: {
			property: '__type',
			subTypes: [
				{ value: GenerateMidjourneyImageTask, name: ImagingProviderEnum.Midjourney.toString() }
			],
		},
	})
	task?: GenerateImageTask;
	@Expose() @Type(() => GeneratedImage) generatedImages?: GeneratedImage[];
}

export class GenerateMidjourneyImageResult extends GenerateImageResult {
	@Expose() hash!: string;
	@Expose() url!: string;
	@Expose() proxyUrl!: string;
	@Expose() width!: number;
	@Expose() height!: number;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.Midjourney;
	}
}
