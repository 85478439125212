import { ChangeDetectionStrategy, Component, effect, inject, Input, OnChanges, signal, SimpleChanges, untracked } from '@angular/core';
import { GenerateImageTask } from '../../models/generate-image-task.models';
import { ImagingService } from '../../services/imagine.service';
import { GlobalSignalingService } from '../../../../shared/services/signaling/global-signaling.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GenerateImageTaskStatusEnum, PostprocessGenerateImageResultStatusEnum } from '../../models/enumerations';


@Component({
	selector: 'app-generate-image-task',
	standalone: true,
	imports: [
		NgxSkeletonLoaderModule
	],
	templateUrl: './generate-image-task.component.html',
	styleUrl: './generate-image-task.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerateImageTaskComponent implements OnChanges {

	private readonly _imagingService = inject(ImagingService);
	private readonly _signalingService = inject(GlobalSignalingService);

	statusText = signal<string | undefined>(undefined);
	task = signal<GenerateImageTask | undefined>(undefined);

	@Input({ required: true, alias: 'task' }) taskInput!: GenerateImageTask;

	constructor() {
		effect(() => {
			const task = this._signalingService.generateImageTaskStatus();
			const result = this._signalingService.postprocessGenerateImageResultStatus();
			if (task?.generateImageTaskId !== this.taskInput.id && result?.generateImageTaskId !== this.taskInput.id) {
				return;
			}
			/* untracked(() => {

			}); */

			const taskStatus = task?.generateImageTaskId === this.taskInput.id ? task.statusId : undefined;
			const postprocStatus = result?.generateImageTaskId === this.taskInput.id ? result.statusId : undefined;
			const text = this.statusToText(taskStatus, postprocStatus);
			this.statusText.set(text);

			if (postprocStatus === PostprocessGenerateImageResultStatusEnum.Succeeded) {
				this._imagingService.getGenerateImageTaskById(this.taskInput.id).subscribe(v => {
					this.task.set(v);
				})
			}

		}, {
			allowSignalWrites: true
		});
	}

	statusToText(taskStatusId?: GenerateImageTaskStatusEnum, postprocessStatusId?: PostprocessGenerateImageResultStatusEnum): string {
		if (!postprocessStatusId) {
			if (taskStatusId === GenerateImageTaskStatusEnum.Initial) {
				return "Планируем...";
			}
			if (taskStatusId === GenerateImageTaskStatusEnum.Generating) {
				return "Генерируем...";
			}
			if (taskStatusId === GenerateImageTaskStatusEnum.Succeeded) {
				return "Генерация завершена";
			}
		}
		else {
			if (postprocessStatusId === PostprocessGenerateImageResultStatusEnum.Initial) {
				return "Планируем обработку...";
			}
			if (postprocessStatusId === PostprocessGenerateImageResultStatusEnum.Processing) {
				return "Обрабатываем...";
			}
			if (postprocessStatusId === PostprocessGenerateImageResultStatusEnum.Succeeded) {
				return "Готово";
			}
		}
		return "Хер пойми";
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.task.set(this.taskInput);
		this.statusText.set(this.statusToText(this.taskInput.statusId, this.taskInput.result?.postprocessStatusId));
	}
}
