<ng-container *transloco="let t; prefix: 'navigation.components.dropdown'">
	<ng-container *ngIf="authResult$ | async as authResult">
		<button mat-icon-button [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<button mat-menu-item *ngIf="authResult.isAuthenticated" [routerLink]="['/generations']">
				<mat-icon>photo_library</mat-icon>
				{{ t("buttonGenerations") }}
			</button>
			<button mat-menu-item *ngIf="authResult.isAuthenticated" [routerLink]="['/profile']">
				<mat-icon>person</mat-icon>
				{{ t("buttonProfile") }}
			</button>
			<button mat-menu-item *ngIf="authResult.isAuthenticated" (click)="logout()">
				<mat-icon>logout</mat-icon>
				{{ t("buttonLogout") }}
			</button>
			<button mat-menu-item *ngIf="!authResult.isAuthenticated" (click)="login()">
				<mat-icon>login</mat-icon>
				{{ t("buttonLogin") }}
			</button>
		</mat-menu>
	</ng-container>
</ng-container>
