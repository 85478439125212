<ng-container *transloco="let t; prefix: 'imaging.components.generateImageForm'">
	<div class="wrapper">
		<form [formGroup]="form" (ngSubmit)="submit()">
			<mat-card appearance="raised">
				<mat-card-header>
					<mat-card-title>{{ t("title") }}</mat-card-title>
				</mat-card-header>
				<div class="progress-wrapper">
					<mat-progress-bar mode="indeterminate" *ngIf="loading()"></mat-progress-bar>
				</div>
				<mat-card-content>
					<div class="form-body">
						<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
							<mat-label>{{ t("promptLabel") }}</mat-label>
							<!-- <input formControlName="prompt" matInput type="text" [errorStateMatcher]="suppressAnyErrorStateMatcher" [placeholder]="t('promptPlaceholder')" app-autofocus /> -->
							<textarea formControlName="prompt" matInput [errorStateMatcher]="suppressAnyErrorStateMatcher" [placeholder]="t('promptPlaceholder')" app-autofocus> </textarea>

							<mat-error>{{ t("promptError") }}</mat-error>
						</mat-form-field>
					</div>
				</mat-card-content>
				<mat-card-actions [align]="'end'">
					<button mat-flat-button color="primary" [disabled]="form.invalid || loading()">{{ t("buttonSubmit") }}</button>
				</mat-card-actions>
			</mat-card>
		</form>
	</div>
</ng-container>
