import { ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule } from '@jsverse/transloco';
import { AutoFocusDirective } from '../../../../shared/directives/auto-focus.directive';
import { ImagingService } from '../../services/imagine.service';
import { SuppressAnyErrorStateMatcher } from '../../../../shared/material/error-state-matchers/suppress-any-error';
import { ImagingProviderEnum } from '../../models/discriminators';
import { CreateGenerateImageTaskRequest } from '../../models/generate-image-task.models';
import { finalize } from 'rxjs';
import { NgIf } from '@angular/common';

@Component({
	selector: 'app-generate-image-task-form',
	standalone: true,
	imports: [
		MatCardModule,
		MatButtonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		AutoFocusDirective,
		MatProgressBarModule,
		TranslocoModule,
		MatIconModule,
		NgIf
	],
	templateUrl: './generate-image-task-form.component.html',
	styleUrl: './generate-image-task-form.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerateImageTaskFormComponent {

	private readonly _imagingService = inject(ImagingService);

	loading = signal(false);

	suppressAnyErrorStateMatcher = new SuppressAnyErrorStateMatcher();

	readonly form = new FormGroup({
		__type: new FormControl<ImagingProviderEnum>(ImagingProviderEnum.Midjourney, [Validators.required]),
		prompt: new FormControl<string>('', [Validators.required]),
	});

	constructor() {
		effect(() => {
			if (this.loading()) {
				this.form.disable();
			}
			else {
				this.form.enable();
			}
		});
	}

	submit() {
		if (this.form.invalid) {
			return;
		}

		this.loading.set(true);

		let request = CreateGenerateImageTaskRequest.fromPlain(<CreateGenerateImageTaskRequest>this.form.value);

		this._imagingService.createGenerateImageTask(request).pipe(
			finalize(() => this.loading.set(false))
		).subscribe(v => {

		});
	}
}
