import { Expose, plainToInstance, Type } from "class-transformer";
import { ImagingProviderEnum } from "./discriminators";
import { GenerateImageTaskStatusEnum } from "./enumerations";
import { GenerateImageResult, GenerateMidjourneyImageResult } from "./generate-image-result.models";

export abstract class GenerateImageTask {
	__type!: ImagingProviderEnum;
	@Expose() id!: number;
	@Expose() prompt!: string;
	@Expose() statusId!: GenerateImageTaskStatusEnum;
	@Expose() completedAt!: Date;
	@Expose() @Type(() => GenerateImageResult, {
		discriminator: {
			property: '__type',
			subTypes: [
				{ value: GenerateMidjourneyImageResult, name: ImagingProviderEnum.Midjourney.toString() }
			],
		},
	})
	result?: GenerateImageResult;

	static fromPlain(object: GenerateImageTask): GenerateImageTask {
		if (object.__type === ImagingProviderEnum.Midjourney) {
			return plainToInstance(GenerateMidjourneyImageTask, object, { excludeExtraneousValues: true });
		}
		throw new Error("Unknown object type");
	}
}

export class GenerateMidjourneyImageTask extends GenerateImageTask {
	@Expose() version?: string;
	@Expose() sref?: string;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.Midjourney;
	}
}

///// Requests

export abstract class CreateGenerateImageTaskRequest {
	@Expose() __type!: ImagingProviderEnum;
	@Expose() prompt!: string;

	static fromPlain(object: CreateGenerateImageTaskRequest): CreateGenerateImageTaskRequest {
		if (object.__type === ImagingProviderEnum.Midjourney) {
			return plainToInstance(CreateGenerateMidjourneyImageTaskRequest, object, { excludeExtraneousValues: true });
		}
		throw new Error("Unknown object type");
	}
}

export class CreateGenerateMidjourneyImageTaskRequest extends CreateGenerateImageTaskRequest {
	@Expose() version?: string;
	@Expose() sref?: string;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.Midjourney;
	}
}

